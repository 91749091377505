import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { sectionPaddingY } from 'styles/global'
import Icons from './Icons'
import EarlyAdoptersData from 'content/earlyAdopters.json'
import { Container, Box, Heading, SimpleGrid, Text } from '@chakra-ui/react'

type EarlyAdoptersParams = {
  button?: ReactNode
}

const BlocEarlyAdopters: FC<EarlyAdoptersParams> = ({ button }) => {
  const { t } = useTranslation()
  const listAdopters: { title: string; icon: any }[] = EarlyAdoptersData.bloc
  return (
    <Container id="bloc-earlyAdopters">
      <Box textAlign="center" p={sectionPaddingY}>
        <Heading maxW="1200px" margin="0 auto">
          {t('earlyAdopters.bloc.title')}
        </Heading>
        <Text
          maxW="1200px"
          m={['1rem auto 0', '1.25rem auto']}
          size="xl"
          color="gray.400"
        >
          {t('earlyAdopters.bloc.subtitle')}
        </Text>
        <SimpleGrid columns={[1, null, 2, 4]} spacing="2rem" mt={['12', '16']}>
          {listAdopters.map((item, idx) => (
            <Box key={idx}>
              <Box mx="auto" mb="4">
                <Icons name={item.icon} />
              </Box>
              <Text size="xl" fontWeight="500" mb={['1', '2']}>
                {item.title}
              </Text>
              <Text color="gray.400">
                {t(`earlyAdopters.bloc.list.${idx}`)}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
        {button && button}
      </Box>
    </Container>
  )
}
export default BlocEarlyAdopters
