import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from 'components/Layout'
import BlocEarlyAdopters from 'components/BlocEarlyAdopters'
import FAQ from 'components/FAQ'
import MainForm from 'components/Form/MainForm'
import Hero from 'components/Hero'
import { useSt } from 'state/global/context'
import { scrollToSection } from 'helpers/scroll'
import { Button } from '@chakra-ui/react'

const EarlyAdoptersPage: FC = () => {
  const { t } = useTranslation()
  const { dispatch } = useSt()
  return (
    <Layout>
      <Hero
        title={t('earlyAdopters.hero.title')}
        description={t('earlyAdopters.hero.subtitle')}
        button={
          <Button
            size="lg"
            variant="primary"
            onClick={() =>
              scrollToSection('main-form', {
                dispatch,
                action: { type: 'updateFormInterest', value: 'adopter' },
              })
            }
          >
            {t('earlyAdopters.hero.button')}
          </Button>
        }
        image={
          <StaticImage
            src="../assets/images/earlyAdopters/hero.png"
            alt="early adopters hero"
            placeholder="blurred"
            style={{ height: '100%' }}
          />
        }
      />
      <BlocEarlyAdopters
        button={
          <Button
            variant="black"
            mt={['10', '16']}
            size="lg"
            w={['full', 'unset']}
            onClick={() => {
              scrollToSection('main-form', {
                dispatch,
                action: { type: 'updateFormInterest', value: 'adopter' },
              })
            }}
          >
            {t('earlyAdopters.bloc.button')}
          </Button>
        }
      />
      <FAQ
        title={t('earlyAdopters.faq.title')}
        subtitle={t('earlyAdopters.faq.subtitle')}
        data={t('earlyAdopters.faq.list', { returnObjects: true })}
      />
      <MainForm
        title={t('earlyAdopters.form.title')}
        subtitle={t('earlyAdopters.form.subtitle')}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default EarlyAdoptersPage
